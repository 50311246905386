"use strict";

jQuery(document).ready(function ($) {
  $('.hamburger').click(function (e) {
    var $this = $(e.currentTarget);

    if ($this.hasClass('close')) {
      $this.removeClass('close');
      $('#menu_holder').removeClass('open');
    } else {
      $this.addClass('close');
      $('#menu_holder').addClass('open');
    }
  });
}); //end jQuery document ready

var $c = function $c(t) {
  console.log(t);
};